export function focusInputGroups(query = document.querySelectorAll('.inputGroup input,.inputGroup select,.inputGroup textarea')) {
    for (let input of query) {
        let inputGroup = input.closest(".inputGroup");
        let infieldInputGroup = input.closest(".infieldInputGroup");

        checkFilledInput(input);

        input.addEventListener("focus", function () {
            inputGroup.classList.add("is-focus");
            if (infieldInputGroup) {
                infieldInputGroup.classList.add("is-focus");
            }
        });

        input.addEventListener("blur", function () {
            inputGroup.classList.remove("is-focus");
            if (infieldInputGroup) {
                infieldInputGroup.classList.remove("is-focus");
            }
        });

        input.addEventListener("input", function () {
            checkFilledInput(input);
        });
    }
}

export function checkFilledInput(input) {
    let inputGroup = input.closest(".inputGroup");
    let infieldInputGroup = input.closest(".infieldInputGroup");

    if(input.dataset.intlTelInputId === undefined){
        if (input.value !== "") {
            if (inputGroup) {
                inputGroup.classList.add("is-filled");
            }
            if (infieldInputGroup) {
                infieldInputGroup.classList.add("is-filled");
            }
        } else {
            if (inputGroup) {
                inputGroup.classList.remove("is-filled");
            }
            if (infieldInputGroup) {
                infieldInputGroup.classList.remove("is-filled");
            }
        }
    }
}