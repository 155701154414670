import Swiper, {Autoplay, Navigation, Pagination, EffectFade} from 'swiper';
import {getOptionsFromJsonString} from "../../../default/global/js/utilities/options";

export function initChronicSlideshow(query = document.querySelectorAll('.chronic__slider')) {
    for (let slideshow of query) {
        let options = getOptionsFromJsonString(slideshow.dataset.options, {
            autoplay_interval: 5000,
            eff_interval: 300,
            effect: 'fade',
        });

        console.log('slideshow started');

        Swiper.use([Autoplay, Navigation, Pagination, EffectFade]);
        new Swiper(slideshow, {
            autoplay: false,
            loop: true,
            items: 1,
            speed: options.eff_interval,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            initialSlide: 0,
            navigation: {
                nextEl: slideshow.querySelector('.swiper-button-next'),
                prevEl: slideshow.querySelector('.swiper-button-prev'),
            },
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
                clickable: true,
            },

        });

    }
}
