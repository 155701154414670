
export function initInputBody(query = document.querySelectorAll(".fileBody")) {
    for(let inputBody of query) {
        let input = inputBody.querySelector("input");
        let span = inputBody.querySelector("span");
        let labelChoose = "Select file";
        let labelChosen = "files selected";

        if (typeof GLOBALS !== 'undefined') {
            if(GLOBALS.tc && GLOBALS.tc.chooseFiles){
                labelChoose = GLOBALS.tc.chooseFiles;
            }
            if(GLOBALS.tc && GLOBALS.tc.chosenFiles){
                labelChosen = GLOBALS.tc.chosenFiles;
            }
        }

        if(input && span) {
            input.addEventListener("change", function () {
                let value = "";
                if(input.files.length > 0) {
                    value = input.files[0].name;
                    if(input.files.length > 1) {
                        value = input.files.length + " " + labelChosen;
                    }
                }else{
                    value = labelChoose;
                }
                span.innerHTML = value;
            });
        }
    }
}