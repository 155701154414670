function checkCountUp(target, count){
    const position = target.getBoundingClientRect();
    let timeout = 3000;
    let i = 0;

    if(count > 100){
        timeout = 1000;
    }


    if((position.y > 0) && (position.y < window.outerHeight) && !target.classList.contains('counted')) {
        target.classList.add('counted');
        let inv = setInterval(function() {
            if(i < count) {
                target.innerHTML = ++i;
            }else{
                clearInterval(inv);
            }
        }, timeout / 100);
    }
}

export function countUp(target){
    let count = 0;

    if(target.dataset.countUp){
        count = parseInt(target.dataset.countUp);
    }

    window.addEventListener("load",function (e) {
        checkCountUp(target,count);
    });

    window.addEventListener("scroll",function (e) {
        checkCountUp(target,count);
    });

    window.addEventListener("orientationchange",function (e) {
        checkCountUp(target,count);
    });
}