
function tabs(tabsWrapperSelector = ".js-tabs", tabsToggleSelector = ".js-tabsToggle", tabsContentSelector = ".js-tabsContent") {

    function showTab(tabIndex,tabsWrapper) {
        let i = 0;
        for(let tabContent of tabsWrapper.querySelectorAll(tabsContentSelector)){
            if(tabIndex === i){
                tabContent.classList.add('active');
            }else{
                tabContent.classList.remove('active');
            }
            i++;
        }
    }

    function setActiveStateForTab(tabIndex,tabsWrapper) {
        let i = 0;
        for(let tabHeader of tabsWrapper.querySelectorAll(tabsToggleSelector)){
            if(tabIndex === i){
                tabHeader.classList.add('active');
            }else{
                tabHeader.classList.remove('active');
            }
            i++;
        }
    }

    function handleClickTab(event) {
        let target = event.currentTarget;

        showTab(parseInt(target.index),target.closest(tabsWrapperSelector));
        setActiveStateForTab(parseInt(target.index),target.closest(tabsWrapperSelector));
    }




    for (let tabWrapper of document.querySelectorAll(tabsWrapperSelector)) {
        let i = 0;
        for (let tabsToggle of tabWrapper.querySelectorAll(tabsToggleSelector)){
            tabsToggle.index = i;
            tabsToggle.addEventListener("click",handleClickTab);
            i++;
        }
        showTab(0,tabWrapper);
        setActiveStateForTab(0,tabWrapper);
    }
}

export default tabs;