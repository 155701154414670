export function scrollingBanner() {
    let multiplicator = 2;
   for (let element of document.querySelectorAll(".scrollingBanner")){
       let textElement = element.querySelector('.contentText');

       window.addEventListener('scroll', function(e) {
           let positionBanner = element.getBoundingClientRect();
           let diff = window.innerHeight - positionBanner.y;
           let percent = diff/(positionBanner.height/100);

           if(percent < 0){
               percent = 0;
           }
           if(percent > 100 * multiplicator){
               percent = 100 * multiplicator;
           }

           textElement.style.left = (100-percent)*multiplicator + "%";
       })
  }
}