import {getChildrenClassForNestedItems} from "../../../default/global/js/nav";
import {initActionClicks, initFormSubmitChanges, initFormSubmitClicks} from "../../../default/global/js/actionClick";
import {focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {stickyHeader} from "../../../default/global/js/stickyheader";
import hoverintent, {removeOtherHoverintents} from "../../../default/global/js/hoverintent";
import {initSlideshows} from "../../../default/global/js/slideshow";
import {openFullscreenGallery} from "../../../default/global/js/fullscreengallery";
import {stickyBox} from "../../../default/global/js/stickyBox.js";
import {buildMobileMenu, hideMobileMenu, showMobileMenu} from "../../../default/global/js/mobilemenu";
import {excludeModal, triggerConfirmModalLink, triggerModal, triggerOpenLinkInModal} from "../../../default/global/js/modal";
import {initToggleSlide} from "../../../default/global/js/toggle";
import {setSrcVideos} from "../../../default/global/js/video";
import {initScrollbars} from "../../../default/global/js/scrollbar";
import {isTouchDevice} from "../../../default/global/js/touchDevice";
import {initCustomSelects} from "../../../default/global/js/customSelect";
import {initJumpmarks} from "../../../default/global/js/jumpmark";
import {trimInputValues} from "../../../default/global/js/formValidator";
import {lottieLoader} from "../../../default/global/js/lottieLoader";
import {initSlidecontents} from "../../../default/global/js/slidecontent";
import {addTableWrapper} from "../../../default/global/js/tableWrapper";
import {initInputBody} from "../../../default/global/js/inputfile";
import {scrollingBanner} from "../../../default/global/js/scrollingBanner";
import {countUp} from "../../../default/global/js/countUp";
import {initChronicSlideshow} from "./chronic";
import tabs from "./tabs";

window.addEventListener("load", (event) => {
    const mobileMenu = document.querySelector('.mobileMenu');
    const mobileMenuToggle = document.getElementById("js-toggleNavigation");
    const overlay = document.getElementById("overlay");
    const IsTouchDevice = isTouchDevice();
    const stickyHeaderArea = document.querySelector('.headerMain');

    let breakPointSM = 1024;

    if (typeof GLOBALS!== "undefined") {
        breakPointSM = GLOBALS.gridBreakpoints.sm;
    }

    if (mobileMenu && mobileMenuToggle && overlay) {
        const mobileMenuClose = mobileMenu.querySelector(".mobileMenu__close");

        buildMobileMenu(mobileMenu);

        mobileMenuToggle.addEventListener("click", function () {
            showMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        })

        mobileMenuClose.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });

        overlay.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });
    }

    initActionClicks();

    initChronicSlideshow();

    initFormSubmitClicks();

    initFormSubmitChanges();

    trimInputValues();

    focusInputGroups();

    initInputBody();

    stickyHeader(stickyHeaderArea);

    if (window.outerWidth < breakPointSM) {
        for (let parent of document.querySelectorAll('.navgroup > ul > li')) {
            getChildrenClassForNestedItems(parent);
        }
    }

    triggerModal();
    excludeModal();
    triggerOpenLinkInModal();
    triggerConfirmModalLink();

    initToggleSlide();

    if (IsTouchDevice) {
        for (let el of document.querySelectorAll('.js-mainNavigation > ul > li > a')) {
            el.addEventListener("click", function (e) {
                let li = el.closest("li");
                let children = li.querySelector("ul");

                if (children === null) {
                    console.log (children);
                    return;
                }
                if (!li.classList.contains("hoverintent")) {
                    removeOtherHoverintents();
                    li.classList.add("hoverintent");
                    e.preventDefault();
                } else {
                    li.classList.remove("hoverintent");
                }
            })
        }
    } else {
        for (let el of document.querySelectorAll('.js-mainNavigation > ul > li')) {
            hoverintent(el);
        }
    }

    setSrcVideos();

    initSlideshows();


    initScrollbars();

    initSlidecontents();

    initCustomSelects();

    let offsetTopJumpmarks = 0;
    if (stickyHeaderArea) {
        offsetTopJumpmarks = stickyHeaderArea.clientHeight;
    }

    if (typeof GLOBALS!== "undefined") {
        if(GLOBALS.offsetTopJumpmarks){
            offsetTopJumpmarks += GLOBALS.offsetTopJumpmarks;
        }
    }

    initJumpmarks(offsetTopJumpmarks);

    for (let item of document.querySelectorAll(".js-openGallery")) {
        item.addEventListener('click', function (event) {
            openFullscreenGallery(item);
        });
    }

    let offsetTopStickyBox = 30;
    if (stickyHeaderArea) {
        offsetTopStickyBox += stickyHeaderArea.clientHeight;
    }

    for (let pinBox of document.querySelectorAll(".js-stickyBox")) {
        stickyBox(pinBox, {
            offsetTop: offsetTopStickyBox
        });
    }

    lottieLoader();

    addTableWrapper();

    scrollingBanner();

    tabs(".tabs",".tabs__header li",".tabs__content");





    for (let el of document.querySelectorAll("[data-count-up]")) {
        countUp(el);
    }
});